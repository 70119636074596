

export function filterNum(num = 0) {
    if ( num < 10 )return "0"+num;
    else return num;
}

export function getDay(maxNum = 30,key?) {
    let newList = [];
    for ( let i = 1;i <= maxNum; i ++ ){
        let data = {
            text:filterNum(i) + " 日",
            value:i-1,
            month:i
        }
        if ( key && i == key ) data["disabled"] = true;
        newList.push(data)
    }
    return newList;
}
